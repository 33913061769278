import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import useNutui from "./utils/useNutui";
import "./utils/flexible";
import "@nutui/nutui/dist/style.css";
import "./style/index.scss";
import "./permission";
import HeaderBar from "./components/HeaderBar.vue";

const app = createApp(App);
app.component("HeaderBar", HeaderBar);
app.use(store);
app.use(router);
useNutui(app);
app.mount("#app");
