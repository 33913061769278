import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "/login",
    component: () => import("../views/user/login.vue"),
    meta: { title: "登录" },
  },
  {
    path: "/trade-center",
    name: "/trade-center",
    component: () => import("../views/capacity/TradeCenter.vue"),
    meta: { title: "产能交易大厅" },
  },
  {
    path: "/company-detail",
    name: "/company-detail",
    component: () => import("../views/capacity/CompanyDetail.vue"),
    meta: { title: "企业详情" },
  },
  {
    path: "/my-center",
    name: "/my-center",
    component: () => import("../views/user/MyCenter.vue"),
    meta: { title: "我的" },
  },
  {
    path: "/my-info",
    name: "/my-info",
    component: () => import("../views/user/MyInfo.vue"),
    meta: { title: "信息管理" },
  },
  {
    path: "/my-order",
    name: "/my-order",
    component: () => import("../views/order/MyOrder.vue"),
    meta: { title: "我的订单" },
  },
  {
    path: "/order-detail",
    name: "/order-detail",
    component: () => import("../views/order/OrderDetail.vue"),
    meta: { title: "订单详情" },
  },
  {
    path: "/create-order",
    name: "/create-order",
    component: () => import("../views/order/CreateOrder.vue"),
    meta: { title: "提交订单" },
  },
  {
    path: "/create-success",
    name: "/create-success",
    component: () => import("../views/order/CreateSuccess.vue"),
    meta: { title: "提交成功" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
