import {
  Button,
  Searchbar,
  Image,
  Badge,
  Empty,
  Popup,
  Dialog,
  Input,
  Tabs,
  TabPane,
  InfiniteLoading,
  Elevator,
  DatePicker,
} from "@nutui/nutui";

export default function useNutui(app) {
  app.use(Button);
  app.use(Searchbar);
  app.use(Image);
  app.use(Badge);
  app.use(Empty);
  app.use(Popup);
  app.use(Dialog);
  app.use(Input);
  app.use(Tabs);
  app.use(TabPane);
  app.use(InfiniteLoading);
  app.use(Elevator);
  app.use(DatePicker);
}
